export const GreekLabel = {
    "Enter_the_following_information_to_enter": "Συμπληρώστε τα στοιχεία σας για να συνδεθείτε",
    "login": "Σύνδεση",
    "Username": "Όνομα Χρήστη",
    "password": "Κωδικός",
    "Orders": "Παραγγελίες",
    "Online": "Σε Σύνδεση",
    "Offline": "Εκτός Σύνδεσης",
    "Info": "Πληροφορίες",
    "email": "Email",
    "vehicletypes": "Τύποι Υπηρεσιών",
    "loginstatus": "Κατάσταση σύνδεσης",
    "lastonlinetime": "Τελευταία ώρα σύνδεσης",
    "fetchingdata": "Εύρεση Πληροφορίας...",
    "poperLoading": "Παρακαλώ περιμένετε",
    "Noinformationfound": "Δεν βρέθηκαν πληροφορίες",
    "Drivers": "Οδηγοί",
    "otherdropoffs": "undefined",
    "paymentmethod": "Τρόπος πληρωμής",
    "Receiver": "Από παραλήπτη",
    "Sender": "Από αποστολέα",
    "Balance": "Υπόλοιπο",
    "Cash": "Μετρητά",
    "Wallet": "Πορτοφόλι",
    "Close": "Κλείσιμο",
    "noVehicleType": "Δεν υπάρχει τύπος υπηρεσίας για εμφάνιση",
    "Fname": "Όνομα",
    "Lname": "Επώνυμο",
    "Pnumber": "Τηλέφωνο",
    "driver": "Οδηγός",
    "Pleasetryanotherkeyword": "Δοκιμάστε μια άλλη λέξη-κλειδί",
    "DropOff": "Παράδοση",
    "Noaddressavailable": "Δεν υπάρχει διαθέσιμη διεύθυνση",
    "Managefavoriteaddresses": "Διαχείριση διευθύνσεων",
    "address": "Διέυθυνση",
    "Saved": "Αποθηκευμένο",
    "block": "τετράγωνο",
    "Floor": "Όροφος",
    "Unit": "Μονάδα",
    "Recieverfullname": "Όνομα Αποστολέα",
    "Recieverphonenumber": "Τηλέφωνο Παραλήπτη",
    "notesfordriver": "Σημείωση για τον οδηγό",
    "Price": "Τιμή",
    "EditOrder": "Επεξεργασία Παραγγελίας",
    "Firstname": "Όνομα",
    "Lastname": "Επώνυμο",
    "Phonenumber": "Τηλέφωνο",
    "senderfullname": "Όνομα Αποστολέα",
    "senderphonenumber": "Τηλέφωνο Αποστολέα",
    "Search": "Αναζήτηση",
    "Service": "Υπηρεσία",
    "Passenger": "Πελάτης",
    "Pickup": "Σημείο Παραλαβής",
    "Pricedetails": "Λεπτομέρειες τιμής",
    "Fee": "Χρέωση",
    "Fornow": "Άμεσα",
    "Scheduled": "Προγραμματισμένο",
    "before": "Πριν:",
    "Settime": "Καταχώρηση ώρας",
    "after": "Μετά:",
    "confirm": "Επιβεβαίωση",
    "Cancel": "Ακύρωση",
    "Addresses": "Διευθύνσεις",
    "Savedaddresses": "Αποθηκευμένες Διευθύνσεις",
    "new": "Νέα",
    "Editaddress": "Επεξεργασία διεύθυνσης",
    "Saveaddress": "Αποθήκευση διεύθυνσης",
    "Language": "Γλώσσα",
    "en": "English",
    "fa": "Persian",
    "English": "English",
    "Persian": "Persian",
    "Logout": "Αποσύνδεση",
    "logotTitle": "Αποσύνδεση απο τον λογαριασμό σας ?",
    "logoutDescription": "Θα αποσυνδεθείτε απο τον λογαριασμό και θα απαιτείτε εκ νέου σύνδεση",
    "confirmLogout": "Επιβεβαίωση",
    "skipLogout": "Ακύρωση",
    "Ascending": "Αύξουσα",
    "Descending": "Φθίνουσα",
    "sort[createdAt]": "Ημερομηνία",
    "sort[pickupScheduleDate]": "Ώρα προγραμματισμένης παραλαβής",
    "sort[statusUpdatedAt]": "Ταξινόμηση κατά ενημέρωση",
    "SelectDateRange": "Εύρος ημερομηνιών",
    "StartDate": "Ημερομηνία Έναρξης",
    "EndDate": "Ημερομηνία Λήξης",
    "Today": "Σήμερα",
    "Yesterday": "Χθες",
    "ThisWeek": "Αυτή την εβδομάδα",
    "LastWeek": "Προηγούμενη εβδομάδα",
    "Last7Days": "Τελευταίες 7 ημέρες",
    "ThisMonth": "Αυτός ο Μήνας",
    "LastMonth": "Προηγούμενος Μήνας",
    "monitoring": "Παρακολούθηση",
    "Haveorder": "Υπάρχει Παραγγελία",
    "Estimated": "Εκτιμώμενος",
    "ReceiverCash": "Μετρητά (παραλήπτη)",
    "SenderCash": "Μετρητά",
    "SenderWallet": "Πορτοφόλι",
    "Auto": "Αυτόματα",
    "Manual": "Χειρ/νητα",
    "SendOrderr": "Αποστολή",
    "Neworder": "Νέα Παραγγελία",
    "Person": "Ιδιώτης",
    "Business": "Επιχείρηση",
    "Businessname": "Όνομα Επιχείρησης",
    "phonenumber": "Τηλέφωνο",
    "Adddropoff": "Προσθήκη σημείου παράδοσης",
    "Searchbynameorphonenumber": "Αναζήτηση σύμφωνα με όνομα ή τηλέφωνο",
    "Fullname": "Όνομα/Επώνυμο",
    "type": "πληκτρολόγηση",
    "only": "xxx μόνο",
    "ChooseDriver": "Επιλογή Οδηγού",
    "AllKey": "Όλες",
    "MeKey": "Εγώ",
    "Vehicletype": "Τύπος οχήματος",
    "status": "Κατάσταση",
    "statusInProgress": "Σε εξέλιξη",
    "statusCanceled": "Ακυρώθηκε",
    "statusPending": "Σε εκκρεμότητα",
    "statusUnassigned": "Χωρίς Ανάθεση",
    "statusDone": "Ολοκληρωμένες",
    "dispatcher": "χειριστής",
    "customer": "τηλέφωνο",
    "regexcode": "Κωδικός",
    "regexcustomerPhone": "Ακυρώθηκε από πελάτη",
    "regexcustomerName": "Τηλέφωνο Πελάτη",
    "regexdriverPhone": "Τηλέφωνο Οδηγού",
    "regexdriverName": "Όνομα Οδηγού",
    "regexdriverUsername": "Όνομα Χρήστη Οδηγού",
    "pickupDescription": "Περιγραφή σημείου παραλαβής",
    "filter": "Φίλτρο",
    "clear": "Καθαρισμός",
    "orderInfoError": "Βλάβη εύρεσης πληροφορίας",
    "Details": "Λεπτομέρειες",
    "History": "Ιστορικό",
    "Phone": "Τηλέφωνο",
    "km": "χλμ",
    "Espath": "Εκτ.Διαδρομή",
    "Receipt": "Απόδειξη",
    "Copylink": "Αντιγραφή συνδέσμου",
    "repeatOrder": "Επανάληψη Παραγγελίας",
    "Edit": "Επεξεργασία",
    "EditPrice": "Επεξεργασία τιμής",
    "Endtrip": "Ολοκλήρωση παραγγελίας",
    "Canceltravel": "Ακύρωση παραγγελίας",
    "CancellText": "Έπειτα της ακύρωσης, η παραγγελία ακυρώνεται και για τον οδηγό.",
    "confirmCancel": "Ναι",
    "Endtravel": "Ολοκλήρωση",
    "EndlText": "undefined",
    "confirmEnd": "Ναι",
    "End": "Ακύρωση Παραγγελίας",
    "Deliveredat": "Παραδόθηκε σε",
    "pod": "Αποδεικτικό παράδοσης",
    "Notes": "Σημείωση",
    "Nonotes": "Καμία σημείωση",
    "Photo": "Φωτογραφία",
    "Signature": "Υπογραφή",
    "Nosignature": "Καμία υπογραφή",
    "Nophoto": "Καμία φωτογραφία",
    "ServiceOptions": "Επιλογές υπηρεσίας",
    "Events": "Γεγονότα",
    "status2": "κατάσταση",
    "Creator": "Δημιουργός",
    "Creationdate": "Ημερομηνία δημιουργίας",
    "Orderid": "ID παραγγελίας",
    "Pickup2": "Σημείο Παραλαβής",
    "Dropoff2": "Σημείο Παράδοσης",
    "PleaseSelect": "Παρακαλώ Επιλέξτε",
    "CreateEvent": "Δημιουργία Γεγονότος",
    "Description": "Περιγραφή",
    "detailsAboutEvent": "Λεπτομέρειες γεγονότος",
    "CustomerVisibility": "Όρατότητα πελάτη",
    "LocationOnMap": "Τοποθεσία στον χάρτη",
    "undefined": "undefined",
    "Pending": "Σε εκρεμμότητα",
    "Unassigned": "Χωρίς Ανάθεση",
    "Assigned": "Σε εξέλιξη",
    "PickedUp": "Σε εξέλιξη",
    "Started": "Σε εξέλιξη",
    "Done": "Ολοκληρώθηκε",
    "CustomerCanceled": "Ακυρώθηκε",
    "DriverCanceled": "Ακυρώθηκε",
    "SupportCanceled": "Ακυρώθηκε",
    "Arrived": "Σε εξέλιξη",
    "Createdsuccessfully": "Δημιουργήθηκε επιτυχώς",
    "Estime": "Εκτιμώμενος χρόνος",
    "Promocode": "Κουπόνι",
    "Updatedsuccessfully": "Ενημερώθηκε επιτυχώς",
    "statusAssigned": "Ανατέθηκε",
    "statusStarted": "Ξεκίνησε",
    "statusPickedUp": "Ξεκίνησε",
    "statusDriverCanceled": "Ακυρώθηκε από οδηγό",
    "statusArrived": "Έφτασε",
    "statusSupportCanceled": "Ακυρώθηκε απο Διαχείριση",
    "Title": "Τίτλος",
    "ContactFullname": "Όνομα Επαφής",
    "Contactphonenumber": "Τηλέφωνο Επαφής",
    "Commission": "Απόδοση",
    "surcharge": "Επιπλέον χρέωση",
    "haslogined": "έχει συνδεθεί",
    "Notloggedin": "δεν έχει συνδεθεί",
    "Schedule": "Προγρ/σμός",
    "Date": "Ημερομηνία",
    "Time": "Ώρα",
    "noDateToShow": "Δεν υπάρχει ημερομηνία προς προβολή",
    "noTimeToShow": "Δεν υπάρχει ώρα προς προβολή",
    "Apply": "Επιβεβαίωση",
    "lowBalance": "Χαμηλό υπόλοιπο",
    "balanceError": "Το υπόλοιπο σας πρέπει να είναι τουλάχιστον ίσο με το κόστος της παραγγελίας",
    "Methods": "Τρόποι Πληρωμής",
    "Addressbook": "Αποθηκευμένες Διευθύνσεις",
    "AddressbookSearchbar": "Αναζήτηση βάση διεύθυνσης, τίτλου και ID",
    "AddressbookDropOf": "Αποθ/μένες Διευθύνσεις",
    "AddressbookPickup": "Αποθ/μένες Διευθύνσεις",
    "hide": "Hide",
    "Nosavedaddress": "Δεν υπάρχουν αποθηκευμένες διευθύνσεις",
    "Romanian": "Romanian",
    "business": "Επιχείρηση",
    "username": "Όνομα Χρήστη",
    "rememberme": "Θυμήσου με",
    "SendOrder": "Αποστολή",
    "code": "Κωδικός",
    "noorder": "καμία παραγγελία",
    "pleaseSellectParsel": "Επιλέξτε Υπηρεσία",
    "Order": "Παραγγελία",
    "Code": "Κωδικός",
    "Editorder": "Επεξεργασία Παραγγελίας",
    "all": "Όλες",
    "Address": "Διευθύνσεις",
    "Block/Building": "Τετράγωνο/Κτήριο",
    "Pickedupat": "Παραλήφθηκε σε",
    "for": "Για",
    "fr": "French",
    "AllVehicletypes": "Όλοι τύποι υπηρεσιών",
    "AllServices": "Όλες οι Υπηρεσίες",
    "French": "French",
    "enterDataCorrectly": "Καταχωρήστε τα στοιχεία σωστά",
    "controlThatbeforeLoweEqualAfter": "Η Ώρα \"Πριν:\" δεν μπορεί να είναι νωρίτερα από \" Μετά:\"",
    "ForceAssign": "Αναγκαστική Ανάθεση",
    "Allvehicletypes": "Όλοι τύποι οχημάτων",
    "waitforconfirm": "xxx (Αναμονή Επιβεβαίωσης)",
    "waitforconfirm2": "(Αναμονή Επιβεβαίωσης)",
    "Dispatching": "Αποστολή",
    "NoDriver": "Κανένας Οδηγός",
    "OtherDetails": "Άλλες Λεπτομέρειες",
    "StatusandDriver": "Κατάσταση και Οδηγός",
    "EditStatusandDriver": "Επεξεργασία Κατάστασης και Οδηγού",
    "Current": "Τρέχων",
    "EDAS_Pending": "Σε εξέλιξη",
    "EDAS_Assigned": "Ανατέθηκε",
    "EDAS_PickedUp": "Παραλήφθηκε",
    "EDAS_Unassigned": "Χωρίς Ανάθεση",
    "EDAS_Started": "Ξεκίνησε",
    "EDAS_Done": "Ολοκληρώθηκε",
    "EDAS_CustomerCanceled": "Ακύρωση από πελάτη",
    "EDAS_DriverCanceled": "Ακύρωση από οδηγό",
    "EDAS_SupportCanceled": "Ακύρωση από Υποστήριξη",
    "EDAS_Arrived": "Έφτασε",
    "Pleasechooseadrivertochangestatus": "Η Αλλαγή επιλογών μπορεί να επηρεάσει την τιμή",
    "icWarningEdit": "Ο τύπος οχήματος θα αλλάξει",
    "Vehicletypewillbechanged": "Ο τύπος οχήματος θα αλλάξει και θα επηρεάσει την τελική τιμή",
    "Yourcurrentvehicletypeanditwillbechangedandeffectsonprice": "Ο τρέχων τύπος οχήματος θα αλλάξει και θα επηρεάσει την τιμή",
    "Rejected": "Απορρίφθηκε",
    "Rejected2": "(Απορρίφθηκε)",
    "DriverVehicleTypeError": "Δεν υπάρχει τύπος οχήματος για τον οδηγό",
    "CodeNameFamilyPhonenumber": "Κωδικός, Όνομα, Τηλέφωνο",
    "youcanselectdriverintheedit": "Μπορεί να επιλεχθεί οδηγός στην Επεξεργασία Κατάστασης και Οδηγού",
    "Earning": "Κέρδος",
    "savePrice": "Αποθήκευση τιμής",
    "saveEarning": "Αποθηκευση κέρδους",
    "Total": "Σύνολο",
    "EditPickupDropOff": "Edit Addresses",
    "noMoreData": "Δεν υπάρχουν άλλες πληροφορίες",
    "OnDemand": "Κατα παραγγελία",
    "PD": "P & D",
    "PD2": "P&D",
    "PickupDropoff": "Παραλαβή, Παράδοση",
    "PickupFlag": "P",
    "DropOffFlag": "D",
    "PickupandDelivery": "Παραλαβή και Παράδοση",
    "PickupDelivery": "Παραλαβή και Παράδοση",
    "Delivery": "Παράδοση",
    "Collecting": "Περισυλλογή",
    "Transit": "Σε μεταφορά",
    "Delivering": "Προς παράδοση",
    "EDAS_Collecting": "Περισυλλογή",
    "EDAS_Transit": "Σε μεταφορά",
    "EDAS_Delivering": "Προς παράδοση",
    "statusCollecting": "Περισυλλογή",
    "statusTransit": "Σε μεταφορά",
    "statusDelivering": "Προς παράδοση",
    "serviceCustomerError1": "xxx δεν είναι διαθέσιμη για αυτόν τον πελάτη",
    "serviceCustomerError2": "Μπορεί να επιλέξει μόνο τη xxx Υπηρεσία",
    "serviceCustomerError3": "Μπορεί να επιλέξει μόνο xxx",
    "OrderCode": "Κωδικός Παραγγελίας",
    "event_Created": "Δημιουργήθηκε",
    "event_Unassigned": "Χωρίς Ανάθεση",
    "event_Assigned": "Ανατέθηκε",
    "event_Started": "Ξεκίνησε",
    "event_Arrived": "Έφτασε",
    "event_PickedUp": "Παραλήφθηκε",
    "event_OrderDone": "Παραγγελία ολοκληρώθηκε",
    "event_DropoffDone": "Έγινε Παράδοση",
    "event_CustomerFeedback": "Ανατροφοδότηση Πελάτη",
    "event_AddPod": "Προσθήκη απόδειξης παράδοσης",
    "event_CustomerCanceled": "Ο πελάτης Ακύρωσε",
    "event_DriverCanceled": "Ο οδηγός ακύρωσε",
    "event_SupportCanceled": "Η υποστήριξη ακύρωσε",
    "event_Updated": "Ενημερώθηκε",
    "event_DriverAssignment": "Ανάθεση Οδηγού",
    "event_DriverReject": "Αππόριψη από οδηγό",
    "event_Collecting": "Περισυλλογή",
    "event_InTransit": "Σε μεταφορά",
    "event_Delivering": "Προς παράδοση",
    "event": "Γεγονός",
    "tab_Delivery": "Παράδοση",
    "tab_Ondemand": "Κατά παραγγελία",
    "tab_Pickup": "Σημείο Παραλαβής",
    "tab_PickupDelivery": "P&D",
    "CurrentStatus": "Τρέχουσα Κατάσταση",
    "Changestatus": "Αλλαγή κατάστασης",
    "Newstatus": "Νέα κατάσταση",
    "Savechanges": "Αποθήκευση αλλαγών",
    "Other": "Άλλα",
    "Shipmentlabel": "Ετικέτα αποστολής",
    "pleaseSellectService": "Επιλέξτε είδος υπηρεσίας",
    "EditPrice2": "Τιμή",
    "EditEarning": "Κέρδος",
    "statusCustomerCanceled": "Ακυρώθηκε απο πελάτη",
    "Dutch": "Dutch",
    "Ondemand": "Κατά παραγγελία",
    "todayat": "Σήμερα στις",
    "tomorrowat": "Αύριο στις",
    "nextWeekat": "Την επόμενη εβδομάδα στις",
    "lastDayat": "Χθες στις",
    "lastWeekat": "Την προηγούμενη εβδομάδα στις",
    "CopyOrderlink": "Αντιγραφή συνδέσμου παραγγελίας",
    "CopyPickuplink": "Αντιγραφή συνδέσμου παραλαβής",
    "CopyDeliverylink": "Αντιγραφή συνδέσουμε παράδοσης",
    "Bulkimport": "Μαζική εισαγωγή",
    "Downloadsamplecsv": "Κατεβάστε ένα δείγμα CSV",
    "processing": "Επεξεργασία",
    "done": "Ολοκληρώθηκε",
    "failed": "Απέτυχε",
    "Draft": "Πρόχειρο",
    "Uploading": "Μεταφόρτωση",
    "Draganddropyourfile": "Σύρετε και αφήστε το αρχείο σας",
    "Onlycsvformatissupported": "Μόνο αρχεία .csv υποστηρίζονται",
    "ORyoucan": "Ή μπορείτε",
    "Openfile": "Άνοιγμα αρχείου",
    "Noimportsyet": "Καμία μεταφόρτωση ακόμα",
    "NoimportsyetDesc": "Μπορείτε να δείτε τη λίστα με τις εισαγωγές σας εδώ και να ελέγξετε την κατάστασή τους",
    "nosupportdialogeTitle": "Αυτός ο τύπος αρχείου δεν υποστηρίζεται",
    "nosupportdialogeText": "Το αρχείο xxx δεν υποστηρίζεται. Μπορείτε να ανεβάσετε μόνο αρχείο .csv συμπεριλαμβανομένων των πληροφοριών παραγγελίας",
    "ok": "OK",
    "Import": "Εισαγωγή",
    "previewTitle": "Προεπισκόπηση και επιλογή",
    "previewDesc": "Αυτό είναι το πρώτο στοιχείο των αρχείων σας για να ελέγξετε ότι οι πληροφορίες σας είναι στη σωστή δομή",
    "Upload": "Μεταφόρτωση",
    "Remove": "Αφαίρεση",
    "ServiceandOrdertype": "Υπηρεσία και Τύπος Παραγγελίας",
    "Fileisproccessing": "Γίνεται επεξεργασία του αρχείου...",
    "proccessingDesc": "Μπορείτε να κάνετε ανανέωση για να δείτε αν το αποτέλεσμα είναι έτοιμο",
    "serverError": "Σφάλμα Διακομιστή",
    "isRequired": "xxx είναι απαραίτητο",
    "Result": "Αποτέλεσμα",
    "ResultText": "Μπορείτε να δείτε τα προβλήματα εδώ και μπορείτε επίσης να κάνετε λήψη αυτών των σειρών για να τα διορθώσετε",
    "ErrorDetails": "Παραγγελίες με σφάλμα",
    "ErrorDetailsText": "Οι παραγγελίες με σφάλματα παρατίθενται στο αρχείο που μπορείτε να κατεβάσετε παρακάτω. Περιέχει λεπτομέρειες σφάλματος για κάθε σειρά.",
    "DownloadErroredRows": "Κατεβάστε τις εσφαλμένες γραμμές",
    "importedsuccessfully": "xxx παραγγελίες έχουν εισαχθεί επιτυχώς",
    "ordershaserrors": "xxx παραγγελίες έχουν σφάλμα",
    "refresh": "Ανανέωση",
    "CashOnDelivery": "Αντικαταβολή",
    "EnterAmount": "Συμπληρώστε το ποσό",
    "COD": "Ανικαταβολή",
    "Surchargexxx": "Έξτρα Χρέωση xxx",
    "AddCreditCard": "Προσθήκη Χρεωστικής Κάρτας",
    "CustomerWallet": "Πορτοφόλι πελάτη",
    "Add": "Προσθήκη",
    "Addedsuccessfully": "Προστέθηκαν επιτυχώς",
    "NoRecentAddress": "Καμία πρόσφατη διεύθυνση",
    "NoRecentAddressD": "Οι τελευταίες διευθύνσεις του πελάτη θα αναφέρονται εδώ",
    "CustomerIsNotChoosed": "Δεν έχει επιλεχθεί πελάτης",
    "CustomerIsNotChoosedD": "Επιλέξτε έναν πελάτη για εμφάνιση (προβολή) της τελευταίας διεύθυνσης",
    "SearchAddress": "Αναζήτηση διεύθυνσης",
    "NoCustomerfound": "Δεν βρέθηκε πελάτης",
    "NoCustomerfoundD": "Εάν ο πελάτης είναι νέος, μπορείτε να τον καταχωρήσετε",
    "NewCustomer": "Νέος Πελάτης",
    "Existing": "Υπάρχον",
    "EmailOtional": "Email (Προεραιτικό)",
    "phonenumberisnotcorrect": "ο αριθμός τηλεφώνου δεν είναι σωστός",
    "Emailisnotcorrect": "Το Email δεν είναι σωστό",
    "Registercustomer": "Εγγραφή πελάτη",
    "incoming": "Εισερχόμενη κλήση",
    "outgoing": "Εξερχόμενη κλήση",
    "recording": "Καταγραφή κλήσης",
    "driveroperatorCallStatus": "Ο οδηγός κάλεσε τον διαχειριστή",
    "customeroperatorCallStatus": "Ο πελάτης κάλεσε τον διαχειριστή",
    "newCustomeroperatorCallStatus": "Νέος πελάτης κάλεσε τον διαχειριστή",
    "operatordriverCallStatus": "Διαχειριστής κάλεσε τον οδηγό",
    "operatorcustomerCallStatus": "Ο διαχειριστής κάλεσε τον πελάτη",
    "operatornewCustomerCallStatus": "Ο διαχειριστής κάλεσε τον νέο πελάτη",
    "dispatcher_Voip": "Διαχειριστής",
    "driver_Voip": "Οδηγός",
    "customer_Voip": "Πελάτης",
    "activeorders": "xxx ενεργές παραγγελίες",
    "Noactiveorder": "Καμία ενεργή παραγγελία",
    "CreateOrder": "Δημιουργία Παραγγελίας",
    "call": "Κάλεσε",
    "spanish": "Spanish",
    "es": "Spanish",
    "networkError": "Δεν υπάρχει σύνδεση στο διαδίκτυο",
    "germany": "Germany",
    "de": "Germany",
    "Itisnotpossibletorepeattherequest": "Η παραγγελία δεν μπορεί να επαναληφθεί",
    "createdAt": "Δημιουργήθηκε στις",
    "dueDate": "Ημερομηνία λήξης",
    "Noresultfound": "Δεν βρέθηκε κάποιο αποτέλεσμα",
    "loadMore": "Περισσότερα",
    "Searchresult": "Αναζήτηση αποτελέσματος",
    "AssignedAt": "Ανάθεση xxx",
    "StartedAt": "Εκκίνηση xxx",
    "ArrivedAt": "Έφτασε xxx",
    "PickedUpAt": "Παραλήφθηκε xxx",
    "DoneAt": "Ολοκληρώθηκε xxx",
    "mindelay": "xxxm καθυστέρηση",
    "minlate": "xxxm καθυστέρηση",
    "PinTop": "Πινέζα",
    "UnPin": "Πινέζα",
    "Itemsonmap": "Αντικείμενα στον χάρτη",
    "min": "xxx λεπτά",
    "Confirming": "Γίνεται επιβεβαίωση",
    "delay": "Καθυστέρηση",
    "late": "Καθυστέρηση",
    "OrderDetails": "Λεπτομέρειες Παραγγελίας",
    "Profile": "Προφίλ",
    "Waitingforavailabledrivers": "Σε αναμονή ενεργών οδηγών",
    "Queue": "Σειρά",
    "OfferingToDrivers": "Προσφορά σε οδηγούς xxx",
    "WaitforAccept": "Αναμονή Αποδοχής",
    "Thereisnoorder": "Δεν υπάρχει παραγγελία",
    "mapitems": "Αντικείμενα χάρτη",
    "Offerings": "Προσφορές",
    "Reject": "Απόρριψη",
    "dropoffCustomerDescription": "Σημείωση Παράδοσης",
    "customerPhoto": "Φωτογραφία Πελάτη",
    "driverPhoto": "Φωτογραφία Οδηγού",
    "NocustomerPhoto": "Καμία Φωτογραφία Πελάτη",
    "NodriverPhoto": "Καμία Φωτογραφία Οδηγού",
    "initial": "αρχική",
    "notifying": "προειδοποίηση",
    "received": "παραλήφθηκε",
    "assigned": "ανατέθηκε",
    "rejected": "απορρίφθηκε",
    "canceled": "ακυρώθηκε",
    "standby": "σε αναμονή",
    "terminated": "τερματίστηκε",
    "urgentTitle": "Αντικατάσταση με Επείγον παράδοση?",
    "urgentDesc": "Αν η παραγγελία χαρακτηριστεί ώς επείγον τότε θα έχει προτεραιότητα για αποστολή",
    "urgentConfirm": "Ναι, ενεργοποίηση \\\"Επείγον\\\"",
    "urgentClose": "Ακύρωση",
    "Urgent": "Επείγον",
    "photos": "Φωτογραφίες",
    "Portuguese": "Portuguese",
    "Dispatchat": "Αποστολή κατά xxx",
    "AutoDispatch": "Αυτόματη αποστολή",
    "Dispatchtime": "Χρόνος αποστολής",
    "Later": "Αργότερα",
    "DispatchingNow": "Αποστολή τώρα",
    "NoResultfromLocalDatabase": "Κανένα αποτέλεσμα από την τοπική βάση δεδομένων",
    "NoResultfromLocalDatabaseD": "Ελέγξτε ξανά ή δοκιμάστε άλλη λέξη-κλειδί",
    "NoResultfromMapService": "undefined",
    "now": "Τώρα",
    "ascending": "Αύξουσα",
    "descending": "Φθίνουσα",
    "Last3Month": "Τελευταίοι 3 Μήνες",
    "Pickupnote": "Σημείωση Παραλαβής",
    "pop": "Αποδεικτικό Παραλαβής",
    "Required": "Απαιτείται",
    "SearchinMapService": "Αναζήτηση στον χάρτη",
    "SearchinLocalDatabase": "Αναζήτηση στην Τοπική βάση δεδομένων",
    "Creditcardnotadded": "Δεν έχει προστεθεί Τραπεζική Κάρτα",
    "OptimizeDropoffs": "Βελτιστοποίηση Παραδόσεων",
    "Greek": "Ελληνικά",
    "el": "Ελληνικά",
    "ReferenceID":"Αναγνωριστικό αναφοράς",
    "EnterId":"Εισαγάγετε ταυτότητα",
    "de":"Γερμανός",
    "German":"Γερμανός",
    "AustrianGerman":"Αυστριακό γερμανικό",
    "Options":"Επιλογές",
    "VehicleOptions":"Επιλογές οχήματος",
    "EditCustomer":"Επεξεργασία πελάτη",
    "NoDriverfound":"Δεν βρέθηκε κανένας οδηγός",
    "NoDriverfoundDesc":"Ελέγξτε τα στοιχεία σας και δοκιμάστε κάτι άλλο",
    "ClearField":"Διαυγές πεδίο",
    "Map":"Χάρτης",
    "Local":"Τοπικός",
    "CompleteRequiredFeileds":"Πλήρης απαιτούμενα πεδία",
    "Senderinfo":"Πληροφορίες αποστολέα",
    "SchedulePickup(Optional)":"Προγραμματίστε παραλαβή (προαιρετικό)",
    "ScheduleDelivery(Optional)": "Schedule Delivery (Optional)",

    "AddDropoff":"Προσθήκη πτώσης",
    "EditPrice":"Επεξεργασία τιμής",
    "Promotion":"Προβολή",
    "tax":"Φόρος",
    "surcharge":"Προσαύξηση xxx",
    "Receiverinfo":"Πληροφορίες δέκτη",
    "tryagain":"Something went wrong, please try again",



    "Selected date and time":"Selected date and time",
    "Reorder stops":"Reorder stops",
    "Bulk Edit Status Summary":"Bulk Edit Status Summary",
    "Successful":"Successful",
    "Failed":"Failed",
    "Order ID":"Order ID",
    "Details":"Details",
    "Result":"Result",
    "Pickup Routed":"Pickup Routed",
    "Delivery Routed":"Delivery Routed",
    "Ref. xxx":"Ref. xxx",
    "Zone":"Zone",
    "Clear":"Clear",
    "Apply":"Apply",
    "No result found":"No result found",
    "Type your keyword":"Type your keyword",
    "Type in the input to start search":"Type in the input to start search",
    "We are sorry ...":"We are sorry ...",
    "The page you are trying to access has restricted access.":"The page you are trying to access has restricted access.",
    "Please refer to your system administrator.":"Please refer to your system administrator.",
    "Failed":"Failed",
    "Done": "Done",
    "Schedule":"Schedule",
    "Proof of delivery":"Proof of delivery",
    "Signature":"Signature",
    "Driver Photo":"Driver Photo",
    "Notes":"Notes",
    "Order Info":"Order Info",
    "Order":"Order",
    "Price":"Price",
    "P_HUB_D Orders":'P_HUB_D Orders',
    "Routes":"Routes",
    "Default pickup":"Default pickup",
    'More':'More',
    'Route name':'Route name',
    'Driver':'Driver',
    'Pickup Orders':'Pickup Orders',
    'Delivery Orders':'Delivery Orders',
    'Ready For Pickup':'Ready For Pickup',
    'PricingAndEarning':"Pricing & Earning",
    "Pricing" : "Pricing",
    "save":"Save",
    "Learn":"Learn",
    "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap"
};